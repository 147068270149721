import firebase from "firebase/app";
import "firebase/messaging";

firebase.initializeApp({
    apiKey: "AIzaSyDdCzvhm00Tr1JuanWnAdbiAOmX91mkrNA",
    authDomain: "ohrey-5014e.firebaseapp.com",
    projectId: "ohrey-5014e",
    storageBucket: "ohrey-5014e.appspot.com",
    messagingSenderId: "903825059301",
    appId: "1:903825059301:web:1292bbb7e70a9636c1d30c",
    measurementId: "G-V7GJMNGBKQ"
})

export default firebase