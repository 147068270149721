<template>
    <b-modal id="modal-daily-report" hide-header hide-footer size="xl" @show="fetchIssues">
        <div class="daily-report p-2">
            <modal-custom-header title="DAILY REPORT" modal-id="modal-daily-report" />
            <validation-observer ref="profileForm" v-slot="{ handleSubmit }">
                <b-form @submit.prevent="handleSubmit(onSubmitIssue)">
                    <!-- <validation-provider tag="div" class="col-12" rules="required" name="report list"
                        ref="report_list" v-slot="{ errors, valid }"> -->
                    <b-select2 class="list-projects mb-3" v-model="issue_id" placeholder="Choose Options" :options="select_issues"
                        :filter-by="issueFilter" :closeOnSelect="false" :loading="isLoading" label="name">
                        <template v-slot:option="option">
                            <slot name="option-data" v-bind="option">
                                <ListIssue :issue="option" @addIssue="addIssue" />
                            </slot>
                        </template>
                    </b-select2>
                    <div class="mb-2" v-if="report_list.length > 0">
                        <div for="preview" class="h5 pb-1">Report List</div>
                    </div>
                    <div v-for="(item, idx) in report_list" :key="idx">
                        <FormIssue :issue="item" @removeIssue="removeIssue" @changeIssue="changeIssue"
                            v-on:load="refreshIssue" @refreshIssue="refreshIssue" :isRefresh="isRefresh" />
                    </div>

                    <div class="d-flex justify-content-end mt-3">
                        <div class="w-100" v-if="isLeavingSoon">
                            <validation-provider name="leave_soon_reason" rules="required">
                                <b-input placeholder="Enter leave soon reason" v-model="leave_soon_reason" required/>
                            </validation-provider>
                        </div>
                        <div class="pl-2">
                            <form-button :block="$device.mobile" type="submit" variant="primary"
                                :disabled="isSubmitting || !!user.today_check_out_at" :loading="isSubmitting"
                                loading-without-hidden-text>
                                SUBMIT
                            </form-button>
                        </div>
                    </div>
                </b-form>
            </validation-observer>
        </div>
    </b-modal>
</template>

<script>
import { mapState } from 'vuex';
import ListIssue from './ListIssue.vue';

export default {
    name: "DailReport",
    watch: {
        item: {
            reportList(val) {
                // do stuff
                console.log(val)
            },
            deep: true
        }
    },
    methods: {
        async fetchIssues() {
            try {
                this.isLoading = true
                const { data } = await this.$http.get(`issues?process=true&order=assigne&assigned_id=${this.$user.id}`);
                if (!data.error) {
                    this.issues = this.$lodash.cloneDeep(data.data.data.filter((el) => {
                        return el !== null && typeof el !== 'undefined';
                    }));
                    this.select_issues = this.$lodash.cloneDeep(data.data.data.filter((el) => {
                        return el !== null && typeof el !== 'undefined';
                    }))
                }
            }
            catch (err) {
                console.log(err);
            } finally {
                this.isLoading = false
            }
        },
        async fetchReportList() {
            try {
                const { data } = await this.$http.get("daily_reports?order=now", {
                    'order': 'now'
                });
                if (!data.error) {
                    this.report_list = this.$lodash.cloneDeep(data.data.data);
                }
            }
            catch (err) {
                console.log(err);
            }
        },
        addIssue(id) {
            this.report_list.push(this.select_issues.find((c) => id == c.id))
            this.select_issues = this.select_issues.filter(function (c) {
                return id != c.id
            });
        },
        removeIssue(id) {
            this.select_issues.push(this.report_list.find((c) => id == c.id))
            this.report_list = this.report_list.filter(function (c) {
                return id != c.id
            });
        },
        changeIssue(issue) {
            let issueIndex = this.report_list.findIndex((c) => issue.id == c.id)
            this.report_list[issueIndex][issue.type] = issue.value
            // this.report_list = issueC
        },
        async refreshIssue(id, stopSpin) {
            await this.fetchIssues()

            let issueIndex = this.report_list.findIndex((c) => id == c.id)
            let issueOriginIndex = this.issues.findIndex((c) => id == c.id)
            this.report_list[issueIndex] = this.$lodash.clone(this.issues[issueOriginIndex])
            this.report_list = this.$lodash.cloneDeep(this.report_list)

            stopSpin()
        },
        async onSubmitIssue() {
            try {
                if (this.report_list.length == 0) {
                    this.$bvModal.hide('modal-daily-report')
                    this.$showAlert({
                        type: 'confirm',
                        title: 'Warning!',
                        message: "Are you sure you want to checkout without select any report?",
                        callback: async ({dismiss}) => {
                            await this.onCheckOut(dismiss)
                        }
                    })
                    
                    return
                }               

                this.isSubmitting = true
                const projects = this.report_list.map(function (obj) {
                    return obj.project.id;
                });
                const issues = this.report_list.map(function (obj) {
                    return obj.id;
                });
                const process = this.report_list.map(function (obj) {
                    return obj.process;
                });
                const status = this.report_list.map(function (obj) {
                    return obj.status;
                });
                const payload = {
                    'projects': projects,
                    'issues': issues,
                    'processes': process,
                    'status': status
                }
                
                await this.$http.post('daily_reports', payload)
                await this.onCheckOut()
            } catch (err) {
                console.log(err)

                if (err.response.data) {
                    this.$showAlert({ type: 'danger', message: err.response.data.message })
                }
            }
        },
        async onCheckOut(dismiss = null) {
            try {
                this.isLoggingTime = true
                const leave_soon_reason = this.leave_soon_reason
                const { data } = await this.$http.post('log-time/attendance', {leave_soon_reason})

                if (!data.error) {
                    if(typeof dismiss == 'function') {
                        dismiss()
                    }                    

                    this.$showAlert({ type: 'success', message: 'Checkout successfully' })
                    this.$bvModal.hide('modal-daily-report')

                    await this.$store.dispatch('fetchUser', true)
                    this.leave_soon_reason = ''
                } else {
                    this.$showAlert({ type: 'danger', message: data.message })
                }
            } catch (err) {
                console.log(err)
                this.$showAlert({ type: 'danger', message: err.response.data.message })
            } finally {
                this.isLoggingTime = false
            }
        },
        onHide() {
            this.leave_soon_reason = ''
        }
    },
    data: () => ({
        issues: [],
        select_issues: [],
        report_list: [],
        issue_id: '',
        isSubmitting: false,
        isLoading: false,
        isRefresh: false,
        leave_soon_reason: '',
        issueFilter: (option, label, search) => {
            let temp = search.toLowerCase();

            return option.name.toLowerCase().indexOf(temp) > -1 ||
                option.status.toLowerCase().indexOf(temp) > -1 ||
                option?.projects.name.toLowerCase().indexOf(temp) > -1;
        },
        queryParams: {
            "process": true
        }
    }),
    computed: {
        ...mapState({
            user: state => state.user || {}
        }),

        isLeavingSoon() {
            const now    = this.$mm(),
                  case_1 = now.isSameOrBefore(this.$mm().hour(11).minute(50)),
                  case_1_sub = now.isSameOrBefore(this.$mm().hour(13).minute(0)),
                  case_2 = now.isSameOrBefore(this.$mm().hour(17).minute(20))

            if(case_1) {
                return true;
            } else if(case_1_sub) {
                return false;
            } else {
                return case_2
            }
        }        
    },
    components: { ListIssue }   
}
</script>
<style lang="scss">
.daily-report {
    .list-projects {
        .vs__dropdown-option {
            --vs-dropdown-min-width: 100%;
            --vs-dropdown-option-padding: 0 0;
            --vs-search-input-placeholder-color: red;
            --vs-dropdown-option--active-bg: red;
            --vs-dropdown-option--active-color: red;
        }
    }
}
</style>
