<template>
    <div class="header">
        <a class="text-primary" style="text-decoration: none" href="/docs" target="_blank">
            <q-icon icon="ant-design:question-circle"/>
        </a>
        <router-link class="text-primary" :to="{name: 'announcements'}">
            <q-icon icon="clarity:bell-solid"/>
        </router-link>
    </div>
</template>

<script>
    export default {
        name: 'HeaderMobile'
    }
</script>

<style lang="scss" scoped>
.header {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.75rem;
    padding: .5rem .75rem;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 48px;
    z-index: 1;
    background: #fff;
}
</style>